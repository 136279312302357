<template>
  <div class="vg_wrapper">
    <el-card>
      <div>
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getDeptsNow()">
          <el-row>
            <el-col>
              <el-form-item>
                <el-button type="success" size="small" @click="exportList" :disabled="!btn.export"
                  ><i class="el-icon-top-right"></i> 导出</el-button
                >
                <el-button size="small" type="warning" @click="showFlag = true">假期管理</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16"></div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`stff_ext_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @getTableData="getDequsNow"
      >
      </search-table>
    </el-card>
    <el-dialog title="假期管理" :visible.sync="showFlag" width="25%">
      <el-row class="vg_mb_5">
        <el-col :md="16" class="vd_dis vd_dis_cen" :offset="4">
          <span style="width: 150px; text-align: right; margin-right: 10px">员工姓名:</span>
          <!-- <el-input size="small" :disabled="searchFlag" v-model="stffFormData.stff_name"  maxlength="10" show-word-limit placeholder="请输入员工姓名"></el-input> -->
          <el-select
            @change="searchDialog"
            v-model="stffFormData.stff_id"
            placeholder="请选择员工姓名"
            filterable
            :disabled="searchFlag"
            size="small"
          >
            <el-option v-for="item in stffListCopy" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :md="12"> -->
        <!-- <el-button :disabled="searchFlag" class="vg_ml_8" size="mini" type="primary" @click="searchDialog">搜索</el-button> -->
        <!-- </el-col> -->
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="16" class="vd_dis vd_dis_cen" :offset="4">
          <span style="width: 150px; text-align: right; margin-right: 10px">去年剩余年假:</span>
          <el-input
            :disabled="!searchFlag"
            size="small"
            v-model="stffFormData.stff_last_break"
            @input="stffFormData.stff_last_break = helper.keepTNum2(stffFormData.stff_last_break)"
            maxlength="10"
            show-word-limit
            placeholder="请输入去年剩余年假"
          ></el-input>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="16" class="vd_dis vd_dis_cen" :offset="4">
          <span style="width: 150px; text-align: right; margin-right: 10px">今年剩余年假:</span>
          <el-input
            :disabled="!searchFlag"
            size="small"
            v-model="stffFormData.stff_this_break"
            @input="stffFormData.stff_this_break = helper.keepTNum2(stffFormData.stff_this_break)"
            maxlength="10"
            show-word-limit
            placeholder="请输入今年剩余年假"
          ></el-input>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="16" class="vd_dis vd_dis_cen" :offset="4">
          <span style="width: 150px; text-align: right; margin-right: 10px">剩余调休:</span>
          <el-input
            :disabled="!searchFlag"
            size="small"
            v-model="stffFormData.stff_off_hour"
            @input="stffFormData.stff_off_hour = helper.keepTNum2(stffFormData.stff_off_hour)"
            maxlength="10"
            show-word-limit
            placeholder="请输入剩余调休"
          ></el-input>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="16" class="vd_dis vd_dis_cen" :offset="4">
          <span style="width: 150px; text-align: right; margin-right: 10px">剩余旅游假:</span>
          <el-input
            :disabled="!searchFlag"
            size="small"
            v-model="stffFormData.stff_tour_break"
            @input="stffFormData.stff_tour_break = helper.keepTNum2(stffFormData.stff_tour_break)"
            maxlength="10"
            show-word-limit
            placeholder="请输入旅游假"
          ></el-input>
        </el-col>
      </el-row>
      <el-row class="vd_dis_cen vg_mt_16">
        <el-col :md="24" class="vd_dis vd_dis_cen">
          <el-button :disabled="!searchFlag" size="small" class="vg_ml_8 vg_mr_32" type="primary" @click="confirmDialog">确认</el-button>
          <el-button :disabled="!searchFlag" size="small" class="vg_ml_8" type="info" @click="empty">清空</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { dpvtAPI } from '@api/modules/dpvt';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@api/modules/department';
import pubPagination from '@/components/common/pubPagination';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { debounce } from 'lodash';
import helper from '@assets/js/helper';

export default {
  name: 'StffList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.index,
        { prop: 'cptt_name', label: '公司名称', itemType: 'input', labelWidth: 200 },
        {
          prop: 'dept_name',
          label: '部门名称',
          itemType: 'select',
          options: [],
          labelWidth: 120,
          filterable: true
        },

        { prop: 'dept_team_name', label: '小组名称', input: false, itemType: 'input', labelWidth: 120 },
        { prop: 'stff_job', label: '员工岗位', input: false, itemType: 'input', labelWidth: 120 },
        {
          prop: 'stff_enter_time',
          label: '入职时间',
          input: false,
          itemType: 'date',
          labelWidth: 120,
          formatter: val => getDateNoTime(val, true)
        },
        { prop: 'stff_name', label: '员工姓名', itemType: 'input', labelWidth: 120 },
        {
          prop: 'stff_last_break',
          label: '去年剩余年假',
          input: false,
          itemType: 'input',
          labelWidth: 120,
          formatter: val => this.formatDate2(val)
        },
        {
          prop: '',
          label: '去年年假有效期',
          input: false,
          itemType: 'input',
          labelWidth: 200,
          formatter: val => {
            return this.thisYear - 1 + '-01-01' + '~' + this.thisYear + '-03-31';
          }
        },
        {
          prop: 'stff_this_break',
          label: '今年剩余年假',
          input: false,
          itemType: 'input',
          labelWidth: 120,
          formatter: val => this.formatDate3(val)
        },
        { prop: 'stff_this_break_time', label: '今年年假有效期', input: false, itemType: 'input', labelWidth: 200 },
        {
          prop: 'stff_off_hour',
          label: '剩余调休',
          input: false,
          itemType: 'input',
          labelWidth: 120,
          formatter: val => this.formatDate4(val)
        },
        {
          prop: 'stff_tour_break',
          label: '剩余旅游假',
          input: false,
          itemType: 'input',
          labelWidth: 120,
          formatter: val => {
            return val + '天';
          }
        },
        {
          prop: 'stff_tour_break_time',
          label: '旅游假有效期',
          input: false,
          itemType: 'input',
          labelWidth: 120,
          formatter: val => {
            return val ? helper.toTimeDay(val) + '~' + (helper.toTimeYear(val) + 1) + '-12-31' : '暂无';
          }
        },
        {
          prop: 'leav_latest_update_time',
          label: '最近更新时间',
          itemType: 'date',
          labelWidth: 120,
          formatter: val => getDateNoTime(val, true)
        }
      ],
      searchForm: {
        stff_name: '',
        stff_last_break: '',
        stff_this_break: '',
        stff_off_hour: '',
        cptt_id: '',
        dept_id: '',
        dept_team_id: '',
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      btn: {},
      loading: true,
      thisYear: '',
      cpttGroupOpt: [],
      deptGroupOpt: [],
      deptTeamsGroupOpt: [],
      showFlag: false,
      stffFormData: {
        stff_id: '',
        stff_tour_break: '',
        stff_off_hour: '',
        stff_this_break: '',
        stff_last_break: ''
      },
      stffListCopy: [],
      searchFlag: false
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/stff_add' || from.path === '/stff_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getDequsNow();
      this.getYear();
      this.getDesiStffListCopy();
      this.getDeptV1();
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.getStffsList();
    }, 1000),
    // 查询
    getDeptsNow() {
      this.currentPage = 1;
      this.getStffsList();
    },
    // 获取员工信息列表
    getStffsList() {
      // const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      // let searchBody = {};
      // searchBody.stff_name = this.searchForm.stff_name;
      // searchBody.start_time = timeNewVal.startTime;
      // searchBody.end_time = timeNewVal.endTime;
      // searchBody.page_no = this.currentPage;
      // searchBody.stff_last_break = this.stff_last_break;
      // searchBody.stff_this_break = this.stff_this_break;
      // searchBody.stff_off_hour = this.stff_off_hour;
      // searchBody.cptt_id = this.searchForm.cptt_id;
      // searchBody.dept_id = this.searchForm.dept_id;
      // searchBody.dept_team_id = this.searchForm.dept_team_id;
      let searchForm = this.$refs.multiTable.searchForm;
      get(dpvtAPI.getDeptVacation, searchForm).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.tableData.forEach(item => {
            item.stff_this_break_time = this.formatLetter(item) + '~' + (this.thisYear + 1) + '-3-31';
          });
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      this.initData();
    },
    // 获取cpttId
    getCpttV1(flag) {
      if (this.cpttGroupOpt.length === 0) {
        get(cpttAPI.getAllCpttsV1).then(res => {
          if (res.data.code === 0) {
            this.cpttGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取人员信息
    getDesiStffListCopy() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffListCopy = res.data.data;
          }
        })
        .catch(() => {});
    },
    // 获取deptId
    getDeptV1() {
      if (this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1).then(res => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
            this.tableProperties[2].options = Array.from(this.deptGroupOpt, ({ dept_name, dept_id }) => {
              return { value: dept_id, label: dept_name };
            });
          }
        });
      }
    },
    // 获取deptTeamId
    getDeptTeams(flag) {
      if (flag === true && this.deptTeamsGroupOpt.length === 0) {
        get(deptAPI.getAllDeptTeams).then(res => {
          if (res.data.code === 0) {
            this.deptTeamsGroupOpt = res.data.data;
          }
        });
      }
    },
    //导出
    exportList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      let searchBody = {};
      searchBody.stff_name = this.searchForm.stff_name;
      searchBody.start_time = timeNewVal.startTime;
      searchBody.end_time = timeNewVal.endTime;
      searchBody.stff_last_break = this.stff_last_break;
      searchBody.stff_this_break = this.stff_this_break;
      searchBody.stff_off_hour = this.stff_off_hour;
      searchBody.cptt_id = this.searchForm.cptt_id;
      searchBody.dept_id = this.searchForm.dept_id;
      searchBody.dept_team_id = this.searchForm.dept_team_id;
      get(dpvtAPI.exportDpvtListV1, searchBody)
        .then(res => {
          if (res.data.code === 0) {
            this.excelUrl = this.helper.megPath(res.data.data.url);
            this.excelName = '部门假期列表' + '.' + 'xlsx';
            this.helper.downloadItemC(this.excelUrl, this.excelName);
            this.$message({ message: '导出成功', type: 'success' });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 搜索员工
    searchDialog() {
      this.getStffsListCopy();
    },
    // 清空
    empty() {
      this.stffFormData = {
        stff_id: '',
        stff_tour_break: '',
        stff_off_hour: '',
        stff_this_break: '',
        stff_last_break: ''
      };
      this.searchFlag = false;
    },
    // 确认
    confirmDialog() {
      post(stffAPI.editStffVacation, this.stffFormData)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '修改成功'
            });
            this.showFlag = false;
            this.empty();
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 搜索数据
    getStffsListCopy() {
      get(dpvtAPI.getDeptVacation, { stff_id: this.stffFormData.stff_id, page_no: 1 }).then(res => {
        if (res.data.code === 0) {
          let list = res.data.data.list;
          if (list.length > 0) {
            // this.stffListCopy.forEach(item=>{
            //   if(item.stff_name === this.stffFormData.stff_name){
            //     this.stffFormData.stff_id = item.stff_id
            //   }
            // })
            this.stffFormData.stff_last_break = list[0].stff_last_break;
            this.stffFormData.stff_this_break = list[0].stff_this_break;
            this.stffFormData.stff_off_hour = list[0].stff_off_hour;
            this.stffFormData.stff_tour_break = list[0].stff_tour_break;
            this.searchFlag = true;
          } else {
            this.$message.warning('暂无此员工');
          }
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.stff_enter_time);
    },
    formatDate1(row) {
      return this.helper.toStringDay(row.leav_latest_update_time);
    },
    formatDate2(val) {
      return val + '天';
    },
    formatLetter(row) {
      if (row.stff_this_break_time === 0) {
        let date = new Date();
        let y = date.getFullYear();
        return y + '-01-01';
      } else {
        return this.helper.toStringDay(row.stff_this_break_time);
      }
    },
    formatDate3(val) {
      return val + '天';
    },
    formatDate4(val) {
      return val + '小时';
    },

    // 员工离职
    formatDateLeft(row) {
      if (row.stff_leave_time === 0) {
        return '在职';
      } else {
        return this.helper.toTimeDay(row.stff_leave_time) + '离职';
      }
    },
    // 员工司龄
    formatDateSen(row) {
      if (row.stff_enter_time) {
        return this.helper.toSeniority(row.stff_enter_time, row.stff_leave_time);
      }
    },
    // 聘用类型
    formatEmpType(row) {
      if (row.stff_labr_cont === 5) {
        return '试用';
      } else if (row.stff_labr_cont === 1) {
        return '返聘';
      } else if (row.stff_labr_cont === 2) {
        return '实习';
      } else if (row.stff_labr_cont === 3) {
        return '劳务';
      } else if (row.stff_labr_cont === 4) {
        return '正式';
      } else {
        return '无生效合同';
      }
    },

    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getStffsList();
    },
    // 获取今年
    getYear() {
      this.thisYear = new Date().getFullYear();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_dis {
  display: flex;
  align-items: center;
}
.vd_dis_cen {
  display: flex;
  justify-content: center;
}
</style>
